import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ILocalization, defaultLocalization } from '../Languages/LanguageInitializer';
import { Inventering, SharePointItem } from '../Functions/ApiRequests';

export type FilterFunction = (item: SharePointItem) => boolean;
export interface FilterDictionary {
  counter: number;
  [key: string]: FilterFunction | number;
}
export type FilterInventeringsFunction = (item: Inventering) => boolean;
export interface FilterInventeringsDictionary {
  counter: number;
  [key: string]: FilterInventeringsFunction | number;
}

interface FilterContextState {
  filters: FilterDictionary;
  setFilters: React.Dispatch<React.SetStateAction<FilterDictionary>>
  inventeringsFilter: FilterInventeringsDictionary, 
  setInventeringsFilter: React.Dispatch<React.SetStateAction<FilterInventeringsDictionary>>
}
export const filterDefaults = {
  filters: {
    counter: 1,
    Status: (item: SharePointItem) => item.Status !== 'Avslutad' && item.Status !== "On Hold",
    Avvikelsetyp: (item: SharePointItem) => item.Avvikelsetyp !== 'Inventering',
  },
  setFilters: () => {},
  inventeringsFilter: {
    counter:1, 
  },
  setInventeringsFilter: () => null
};

export const FilterContext = createContext<FilterContextState>(filterDefaults);

export const useFilters = () => useContext(FilterContext);

interface AppContextProviderProps {
  children: ReactNode;
}

// AppContextProvider component
// AppContextProvider component
export const FilterContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<FilterDictionary>(filterDefaults.filters);
  const [inventeringsFilter, setInventeringsFilter] = useState<FilterInventeringsDictionary>(filterDefaults.inventeringsFilter)
  return (
    <FilterContext.Provider value={{ filters, setFilters, inventeringsFilter, setInventeringsFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

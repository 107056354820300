import axios from 'axios';
import React from 'react';
import resize  from 'react-image-file-resizer';
import { getApiEndpoint } from '../constants/Endpoints';
import { Buffer } from 'buffer';
import { message } from 'antd';

const apiUrl = "https://graph.microsoft.com"

export interface SharePointItem {
  __metadata: {
    id: string;
    uri: string;
    etag: string;
    type: string;
  };
  Kund:string;
  Nyf_x00f6_rs_x00e4_ljning:boolean;
  UserPrincipalName:string;
  Anbudskalkyll?:string;
  Avslutadanledning:string;
  Avslutadkommentar:string;
  FirstUniqueAncestorSecurableObject: Deferred;
  RoleAssignments: Deferred;
  AttachmentFiles: Deferred;
  ContentType: Deferred;
  GetDlpPolicyTip: Deferred;
  FieldValuesAsHtml: Deferred;
  FieldValuesAsText: Deferred;
  FieldValuesForEdit: Deferred;
  File: Deferred;
  Folder: Deferred;
  LikedByInformation: Deferred;
  ParentList: Deferred;
  Properties: Deferred;
  Versions: Deferred;
  Id: number;
  Title: string;
  ComplianceAssetId: null | string;
  Beskrivning: string;
  Avvikelsetyp: string;
  Latitud: string;
  Longitud: string;
  Namn: string;
  Uppskattad_x0020_tids_x00e5_tg_x: null | string;
  Kan_x0020_avklaras: boolean;
  Status: string;
  Anv_x00e4_ndar_x002d_ID: number;
  Information: null | string;
  Godk_x00e4_ntpris: null | string;
  Kommentarvidavslutande: null | string;
  Fastighet: string;
  Ort: string;
  Adress: null | string;
  AnbudSkapat:boolean;
  Titel: null | string;
  Tilldeladtill?: string;
  Material: null | {
    __metadata: {
      type: string;
    };
    results: string[];
  }
  Google_x0020_Maps: string;
  OData__x00c4_tatyp: string;
  ID: number;
  Created: string;
  Attachments: boolean;
  attachmentUris: string[];
  Images?: any[];
}

interface Deferred {
  __deferred: {
    uri: string;
  };
}

export interface AttachmentFile {
  content: string;
  contentType: string;
}

export interface PatchableSharePointItem {
  Title: string | null | undefined;
  Beskrivning: string | null | undefined;
  Avvikelsetyp: string | null | undefined;
  Latitud: string | null | undefined;
  Longitud: string | null | undefined;
  Namn: string | null | undefined;
  Material?: {
    __metadata: {
      type: string;
    };
    results: string[];
  } | null | undefined | any;
  Kan_x0020_avklaras: boolean | null | undefined;
  Status: string | null | undefined;
  Fastighet: string | null | undefined;
  Ort: string | null | undefined;
  Adress: string | null | undefined;
  Google_x0020_Maps: string | null | undefined;
  ID: number;
  Godk_x00e4_ntpris: string | null | undefined;
  Kommentarvidavslutande: string | null | undefined;
  Uppskattad_x0020_tids_x00e5_tg_x: string | null | undefined;
  OData__x00c4_tatyp: string | null | undefined;
  Information: string | null | undefined;
  attachmentUris: string[]
  AnbudSkapat?: boolean;
  AttachmentFiles: {
    content: string;
    contentType: string;
  }[] | null | undefined;
}

export type Inventering = {
		"AttachmentFiles": {
			"__deferred": {
				"uri": string
			}},
		"FileSystemObjectType": number,
		"Id": number,
		"ServerRedirectedEmbedUri": null,
		"ServerRedirectedEmbedUrl": string,
		"ContentTypeId": string,
		"Title": string,
		"OData__ColorTag": null,
		"ComplianceAssetId": null,
		"Latitud": number,
		"Longitud": number,
		"Kommentar": string,
		"Avvikelse": null,
		"Kategori": string,
		"KompletteraBytaTabort": string,
		"Reflex": null,
		"Stolpreflex": boolean,
		"Kondition": null,
		"Justering": boolean,
		"Prioritet": boolean,
		"MaterialRidstigar": string,
		"RidstigarAvvikelse": string,
		"ID": number,
		"Modified": string,
		"Created": string,
		"AuthorId": number,
		"EditorId": number,
		"OData__UIVersionString": string,
		"Attachments": boolean,
		"GUID": string
	}



export const apiClient = axios.create({
  baseURL: apiUrl,
  
  headers: {
    "Content-Type": "application/json",
    "serializedOrigin":null,
    "Authorization": "Bearer " + sessionStorage.getItem("GRAPHTOKEN"),
    "Access-Control-Allow-Origin":"*"
  },
});
export const greenAppsClient = axios.create({
  baseURL: "https://greenapps-dev.azurewebsites.net",

  headers: {
    "Content-Type": "application/json",
    "Origin": "no-referrer",
    "serializedOrigin": null,
    "Authorization": "Bearer " + sessionStorage.getItem("GRAPHTOKEN"),
    "Access-Control-Allow-Origin": "*"
  },


});

export interface SharePointAttachment {
  id: number;
  AttachmentFiles: Attachment[]
}
interface Attachment {
  content: string;
  contentType: string;
}

export const getSharepointItems = async (ID: number | null, accessToken: string) => { 

  const url = getApiEndpoint().allItems;
  const result = await axios.get(url, { headers: { "Authorization": "Bearer " + accessToken, "Accept": 'application/json;odata=verbose', "Content-Type": 'application/json;odata=verbose' } });
  let res = result.data.d.results as SharePointItem[];
  console.log("UPN", sessionStorage.getItem("USERPRINCIPALNAME"))
  if (!["Henrik.Magnusson@greenlandscaping.se", "nicklas.ferhm@greenlandscaping.se", "Mutaz.Nafel@greenlandscaping.se", "andreas.lofkvist@GLNET.SE", "lars.bornesvard@greenlandscaping.se"].includes(sessionStorage.getItem("USERPRINCIPALNAME")!) ){
    res = res.filter(item => !item.Nyf_x00f6_rs_x00e4_ljning)
  }
  else if (sessionStorage.getItem("USERPRINCIPALNAME") === "Mutaz.Nafel@greenlandscaping.se"){
      res = res.filter(item => {
        if (item.Nyf_x00f6_rs_x00e4_ljning){
          return item.UserPrincipalName === "Mutaz.Nafel@greenlandscaping.se"
        }
        else return true
      })
    }
    else if (sessionStorage.getItem("USERPRINCIPALNAME") === "Henrik.Magnusson@greenlandscaping.se"){
      res = res.filter(item => {
        if (item.Nyf_x00f6_rs_x00e4_ljning){
          return item.UserPrincipalName === "Henrik.Magnusson@greenlandscaping.se"
        }
        else return true
      })
    }
  
  
  return res;
}

export const getInventeringar = async (ID: number | null, accessToken: string) => { 

  const url = getApiEndpoint().inventeringar;
  const result = await axios.get(url, { headers: { "Authorization": "Bearer " + accessToken, "Accept": 'application/json;odata=verbose', "Content-Type": 'application/json;odata=verbose' } });
  let res = result.data.d.results as Inventering[];

  return res;
}

export const getUsers = async () => {
  const payload = {
    method: "get",
      headers: { },
    url: apiUrl + "/v1.0/groups/78d4a33c-eedf-41d7-973d-d1da63a41bfc/members"
  }
  const response = await apiClient.get("/v1.0/groups/78d4a33c-eedf-41d7-973d-d1da63a41bfc/members");
  return response.data;
}

export async function getImagesForItem(item: SharePointItem, accessToken: string, allItems?: SharePointItem[], setAllItems?: React.Dispatch<React.SetStateAction<SharePointItem[]>>): Promise<Attachment[]> {
  const url = item.AttachmentFiles.__deferred.uri;
  const result = await axios.get(url, { headers: { "Authorization": "Bearer " + accessToken, "Accept": 'application/json;odata=verbose', "Content-Type": 'application/json;odata=verbose' } });
  const attachmentUris = result.data.d.results.map((r: any) => r.__metadata.uri + "/$value");
  const attachments = await Promise.all(attachmentUris.map((e: string) => getImage(e, accessToken)))
  if (setAllItems && allItems) {
    const tempItem = { ...item, Images: attachments }
    const Items = [tempItem, ...allItems].reduce((acc: SharePointItem[], currentItem) => {
      const existingItem = acc.find((item) => item.ID === currentItem.ID);
      if (!existingItem) {
        acc.push(currentItem);
      }
      return acc;
    }, []);
    setAllItems(Items)
  }
  return attachments
}

export async function getImages(items: SharePointItem[], accessToken: string): Promise<SharePointAttachment[]> {
  const attachments = await Promise.all(items.map(async (item) => {
    const attachmentFiles = await getImagesForItem(item, accessToken);
    return {
      id: item.ID,
      AttachmentFiles: attachmentFiles,
    };
  }));
  return attachments;
}

export async function getAttachments(item: SharePointItem | Inventering, accessToken: string) {
  const url = item.AttachmentFiles.__deferred.uri;
  const result = await axios.get(url, { headers: { "Authorization": "Bearer " + accessToken, "Accept": 'application/json;odata=verbose', "Content-Type": 'application/json;odata=verbose' } });
  const attachmentUris = result.data.d.results.map((r: any) => r.__metadata.uri + "/$value");
  const images = await Promise.all(attachmentUris.map((uri: string) => getImage(uri, accessToken)))
  return images
}

interface PatchPayload {
  "Title": string,
  Beskrivning: string,
  Avvikelsetyp: string,
  Fastighet?: string,
  Godk_x00e4_ntpris?: string,
  OData__x00c4_tatyp: string,
  Ort?: string,
  Uppskattad_x0020_tids_x00e5_tg_x?: string,
  Adress?: string,
  Status: string,
  Namn: string,
  Information?: string,
  Material?: string[]
}

export async function getImage(url: string, accessToken: string) {
  try {
    const result = await axios.get(url, {
      headers: {
        "Authorization": "Bearer " + accessToken,
        "Accept": 'application/json;odata=verbose',
        "Content-Type": 'application/json;odata=verbose'
      },
      responseType: 'blob'
    });

    const binaryData = result.data;
    const imag = new Blob([binaryData])

    return new Promise<{ content: string, contentType: string }>((resolve, reject) => {
      resize.imageFileResizer(imag, 180, 180, 'png', 0.9, 0, (resizedImage) => {
        const resizedImg = resizedImage as Blob;
        const reader = new FileReader();
        reader.readAsDataURL(resizedImg);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve({
            content: base64data,
            contentType: "image/png"
          });
        };
      }, 'blob');
    });
  } catch (error) {
    console.error("Error fetching or resizing image:", error);
    throw error;
  }
}

export async function getAnbudsDokument(accessToken:string, customFile?:string){
  const url = `https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/GetFolderByServerRelativeUrl(${customFile ? `'Delade dokument/ÄTA-filer/Anbudskalkyl')/Files('${customFile}')` : "'Delade dokument/ÄTA-filer')/Files('Uträkning.xlsx')"}/$value` //getfilebyserverrelativeurl('${file_path}')/$value"`
  const result = await axios.get(url, 
  {
    headers: {
      Authorization: "Bearer " + accessToken
  },
  responseType:'arraybuffer'
})
  
  return Buffer.from(result.data, 'binary')
}

export async function uploadAnbudsDokument(editedItem: SharePointItem, access_token: string, setAllItems:any, setCurrentTab:any) {
  const newFileName = `${editedItem.ID} Anbudskalkyl.xlsx`
  const anbudsdokument = await getAnbudsDokument(access_token)

  const uploadUrl = `https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/GetFolderByServerRelativeUrl('Delade dokument/ÄTA-filer/Anbudskalkyl')/Files/add(url='${newFileName}', overwrite=true)`;
  const uploadResult = await axios.post(uploadUrl, anbudsdokument, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/octet-stream',
      'Content-Length': anbudsdokument.length
    }
  });

  editedItem.Anbudskalkyll = uploadResult.data.LinkingUrl;
  await patchSharepointItems(editedItem, access_token);
  await getSharepointItems(null, access_token).then((items) => {
      setAllItems(items);
      message.success('Laddade upp en ny anbudskalkyl.');
      setCurrentTab('EditJob');
  })
  
  return uploadResult.data;
}

export async function getAnbudsDokumentWord(accessToken: string, customFile?: string) {
  const url = `https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/GetFolderByServerRelativeUrl(${customFile ? `'Delade dokument/ÄTA-filer/AnbudsDokument')/Files('${customFile}')` : "'Delade dokument/ÄTA-filer')/Files('Anbudsmall.docx')"}/$value` //getfilebyserverrelativeurl('${file_path}')/$value"`
  const result = await axios.get(url,
    {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      responseType: 'arraybuffer'
    })

  return Buffer.from(result.data, 'binary')
}

export async function getFields(access_token:string){
    const url = getApiEndpoint().fields;
    const result = await axios.get(url, { headers: { "Authorization": "Bearer " + access_token, "Accept": 'application/json;odata=verbose', "Content-Type": 'application/json' } });
    let res = result.data.d.results as any[]
    return res;
  
}

export async function createAnbudsDokument(editedItem:SharePointItem, access_token:string){
  const kalkyl = await getAnbudsDokument(access_token, `${editedItem.ID} Anbudskalkyl.xlsx`);
  const doc = await getAnbudsDokumentWord(access_token);
  const payload = {Item: editedItem, doc:doc.toString('base64'), kalkyl:kalkyl.toString('base64')};
  const response = await axios.post("https://prod-229.westeurope.logic.azure.com:443/workflows/0437003be20547b283c195a1b98304df/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5RYSWwN6v0hE2fznzr9TQmJp6MTIRzU6mjZV9uDOStk",payload);
  return response
}


export const patchSharepointItems = async (payload: SharePointItem, accessToken: string) => {
  if (payload.Material && (payload.Material as any).__metadata) {
    payload.Material = (payload.Material as any).results
  }

  const pload = {
    Avslutadkommentar: payload.Avslutadkommentar,
    Avslutadanledning: payload.Avslutadanledning,
    "Title": payload.Title,
    Beskrivning: payload.Beskrivning,
    Avvikelsetyp: payload.Avvikelsetyp,
    Fastighet: payload.Fastighet,
    Godk_x00e4_ntpris: payload.Godk_x00e4_ntpris,
    OData__x00c4_tatyp: payload.OData__x00c4_tatyp,
    Ort: payload.Ort,
    Uppskattad_x0020_tids_x00e5_tg_x: payload.Uppskattad_x0020_tids_x00e5_tg_x,
    Adress: payload.Adress,
    Status: payload.Status,
    Namn: payload.Namn,
    Information: payload.Information,
    Anbudskalkyll: payload.Anbudskalkyll
  } as PatchPayload

  const result = await axios.post(getApiEndpoint().patchItem(payload.ID), pload, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json; odata=minimalmetadata",
      "X-HTTP-Method": "MERGE",
      "If-Match": "*"
    }

  });
  return result.data
}


function convertToBase64Uri(binaryFile: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(binaryFile);
  });
}
